import React from 'react'
import Layout from '../components/layout'

const PrivacyPolicyPage = () => (
  <Layout>
    <div id="main" className="alt">
        <section id="one">
            <div className="inner">
              <h2>プライバシーポリシー</h2>
              <p>
                omeroid株式会社（以下、「当社」という。）は、ユーザーの個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。本ポリシーは、当社がどのような個人情報を取得し、どのように利用・共有するか、ユーザーがどのようにご自身の個人情報を管理できるかをご説明するものです。
              </p>

              <h4>第1条（事業者情報）</h4>
              <p>
                法人名：omeroid株式会社<br />
                住　所：東京都大田区平和島6丁目1番1号<br />
                代表者：松野 貞之
              </p>

              <h4>第2条（個人情報）</h4>
              <p>
                「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別符号）を指します。
              </p>

              <h4>第3条（個人情報の取得方法）</h4>
              <p>
                当社は、ユーザーが当社サービスの利用登録、及び問い合わせをする際に氏名、生年月日、住所、電話番号、メールアドレス、銀行口座番号、クレジットカード番号、運転免許証番号などの個人情報をお尋ねすることがあります。<br />
                また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を、当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などから取得することがあります。
              </p>

              <h4>第4条（個人情報の利用目的）</h4>
               <p>
                当社が個人情報を取得・利用する目的は、以下のとおりです。<br />
                <ul>
                  <li>当社サービスの提供・運営のため</li>
                  <li>ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</li>
                  <li>ユーザーが利用中のサービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため</li>
                  <li>メンテナンス、重要なお知らせなど必要に応じたご連絡のため</li>
                  <li>利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため</li>
                  <li>ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため</li>
                  <li>有料サービスにおいて、ユーザーに利用料金を請求するため</li>
                  <li>上記の利用目的に付随する目的</li>
                </ul>
              </p>
              
              <h4>第5条（利用目的の変更）</h4>
              <p>
                当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。<br />
                利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、ユーザーに通知し、または本ウェブサイト上に公表するものとします。
              </p>
              
              <h4>第6条（個人データを安全に管理するための措置）</h4>
              <p>
                当社は、個人情報を正確かつ最新の内容に保つよう努め、不正なアクセス・改ざん・漏えい・滅失及び毀損から保護するため全従業員及び役員に対して教育研修を実施しています。また、個人情報保護規程を設け、現場での管理についても定期的に点検を行っています。
              </p>

              <h4>第7条（個人データの第三者提供）</h4>
              <p>
                当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。<br />
                <ul>
                  <li>ただし、個人情報保護法その他の法令で認められる場合を除きます。</li>
                  <li>人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
                  <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
                  <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
                  <li>予め次の事項を告知あるいは公表し、かつ当社が個人情報保護委員会に届出をしたとき
                    <ul>
                      <li>利用目的に第三者への提供を含むこと</li>
                      <li>第三者に提供されるデータの項目</li>
                      <li>第三者への提供の手段または方法</li>
                      <li>本人の求めに応じて個人情報の第三者への提供を停止すること</li>
                      <li>本人の求めを受け付ける方法</li>
                    </ul>
                  </li>
                  <li>前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
                    <ul>
                      <li>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
                      <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
                      <li>個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合</li>
                    </ul>
                  </li>
                </ul>
              </p>
              
              <h4>第8条（匿名加工情報に関する取扱い）</h4>
              <p>
                当社は、匿名加工情報（特定の個人を識別できないよう加工した個人情報であって、復元ができないようにしたもの）を作成する場合、以下の対応を行います。
                <ul>
                  <li>法令で定める基準に従い適正な加工を施す</li>
                  <li>法令で定める基準に従い安全管理措置を講じる</li>
                  <li>匿名加工情報に含まれる個人に関する情報の項目を公表する</li>
                  <li>作成元となった個人情報の本人を識別するため、他の情報と照合すること</li>
                </ul>
              </p>

              <h4>第9条（個人情報の開示）</h4>
              <p>
                当社は、本人から個人情報の開示を求められたときは、ユーザー本人に対し、遅滞なくこれを開示します。<br />
                ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
                <ul>
                  <li>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
                  <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
                  <li>その他法令に違反することとなる場合</li>
                  <li>前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。</li>
                </ul>
                <br />
                個人情報の開示を希望される方は、以下の手続きに従ってご請求ください。<br />
                以下の書類等をお問い合わせ窓口宛てにご郵送願います。
                <ul>
                  <li>保有個人データ開示等請求書</li>
                  <li>本人確認書類の写し（運転免許証、個人番号カード、在留カード、特別永住者証明書、パスポートなどの顔写真付き証明書）</li>
                  <li>手数料（事務手数料として1請求につき1,000円）</li>
                </ul>
                送付先住所：〒143-0006<br />
                　　　　　　東京都大田区平和島6丁目1番1号　東京流通センターB棟1階(W1-4～7）まつの内<br />
                　　　　　　omeroid株式会社　お問い合わせ窓口
              </p>
              
              <h4>第10条（個人情報の訂正および削除）</h4>
              <p>
                ユーザーは、当社の保有する自己の個人情報が誤った情報である場合、当社が定める手続きにより、当社に対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。<br />
                当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとします。<br />
                当社は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは遅滞なく、これをユーザーに通知します。
              </p>

              <h4>第11条（個人情報の利用停止等）</h4>
              <p>
                当社は、本人から個人情報が利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。<br />
                前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。<br />
                当社は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。<br />
                前項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。
              </p>

              <h4>第12条（SSL（Secure Socket Layer）について）</h4>
              <p>
                当社のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。
              </p>

              <h4>第13条（cookieについて）</h4>
              <p>
                cookieとは、WebサーバーからWebブラウザに送信されるデータのことです。Webサーバーがcookieを参照することでユーザーのパソコンを識別でき、効率的に当社Webサイトを利用することができます。当社Webサイトがcookieとして送るファイルは、個人を特定するような情報は含んでおりません。<br />
                お使いのWebブラウザの設定により、cookieを無効にすることも可能です。
              </p>

              <h4>第14条（免責事項）</h4>
              <p>
                当社Webサイトに掲載されている情報の正確性には万全を期していますが、利用者が当社Webサイトの情報を用いて行う一切の行為に関して、一切の責任を負わないものとします。<br />
                当社は、利用者が当社Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。
              </p>

              <h4>第15条（著作権・肖像権）</h4>
              <p>
                当社Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されています。無断での使用や転用は禁止されています。
              </p>

              <h4>第16条（リンク）</h4>
              <p>
                当社Webサイトへのリンクは、自由に設置していただいて構いません。ただし、Webサイトの内容等によってはリンクの設置をお断りすることがあります。
              </p>

              <h4>第17条（プライバシーポリシーの変更）</h4>
              <p>
                本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。<br />
                当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
              </p>

              <h4>第18条（お問い合わせ窓口）</h4>
              <p>
                本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。<br />
                Eメールアドレス: inquiry@omeroid.com
              </p>

              <p>
                2018年2月1日制定<br />
                2022年4月1日改定
              </p>
            </div>
        </section>
    </div>
  </Layout>
)

export default PrivacyPolicyPage